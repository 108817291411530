import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "No FireMotD ExportFile detected",
  "path": "/Advanced_User/openHABian_on_Raspberry_Pi/No_FireMotD_ExportFile_detected/",
  "dateChanged": "2018-09-28",
  "author": "Mike Polinowski",
  "excerpt": "The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHABian aims to provide a self-configuring Linux system setup specific to the needs of every Raspberry Pi user. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_openHABian.png",
  "social": "/images/Search/AU_SearchThumb_openHABian.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='No FireMotD ExportFile detected' dateChanged='2018-09-28' author='Mike Polinowski' tag='INSTAR IP Camera' description='The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_openHABian.png' twitter='/images/Search/AU_SearchThumb_openHABian.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/openHABian_auf_Raspberry_Pi/No_FireMotD_ExportFile_detected/' locationFR='/fr/Advanced_User/openHABian_on_Raspberry_Pi/No_FireMotD_ExportFile_detected/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "no-firemotd-exportfile-detected",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#no-firemotd-exportfile-detected",
        "aria-label": "no firemotd exportfile detected permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`No FireMotD ExportFile detected`}</h1>
    <p>{`If you see the Error message `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`FireMotD: Info: No FireMotD ExportFile detected`}</code>{` after starting openHABian for the first time, follow this Guide to get FireMotD set up:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ce6e1ac296f3cc56ed87bed49ef06a47/e9beb/OpenHabian_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.17391304347825%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADNUlEQVQ4yyWSTXOcOBRF+Su2G5CQBIhvSUgCARICutt2Yjt2kipnNrOZ/7+eaqfq1Nve+26dYD/cYo0ymque90xrwbUSgxJaMSn62+Vc8q7nKMUJhglBgOAY4yiBwdPT+Tg7oaUyRk+jmcw4GbOMs5s71jRtWTU3aJmRDCUYJBhAgmKMIEqCp+9Xfzg5z3qZtVv4oBrBaFtxxRvRpUWalpTQ9CF+CMEpSuIQwSiJYwRPGAT/evvbzS/GfNP6wtmVtVfeXXl3aatnwS5ds1XUFbmjmc3Tlea+oL7IZ5p+xyj4I/m77q9dd23bLUu3lPgbeCXIp/hMsz1LPUFnmm4ZOWh6KfILzWxG/hAcMF4XZVqyth8U61leZLTMiipvmpIWWVlTWqRphiAMw+g+DO+i6C6M7kD8cIJhIPq2aUsmBesZl5yJjomuE20n2qopaJmVVZ5TAlEUgVMMTlF8D2AIUZygOOhYRYsU5yQvc1rmCQaIwKIuaEURgWH8EMNTGD2AJEIkQQQiAnGKIAYwiYK3t+fzeZ+s9cemRzUYtVgzLcZMg9Tcuskfq13n0Sg99LTMIAIQRV/EwfF4+IvXbpoPt5ydtqOa1LzZwU79IKVRxs3DMuYNLWr6t3AMQ4BiiOPg9fCPbr4sejfyeTePq9ZN3mIoMqTKvE7iPLzPw7sKRhgBgMCtMIqi+D6M7oL/7PJrtb+n7tPzf3bx6dlrX5yL4rlr3qX4wVuPwE7QkQCDYPxlWELgbTlwCj6/XZ/WZTPyuppjVo9u3MZ+6tk6yKudnOROMivZpvu+rcDN7ST5Wi7BMHj/9bZdvJqG2S/DPLrD293JUU7O7NfdXzZ3rHrSjWjzuoAE3sAQ3l6Ig4+Pl/1YB6OcXwaj/O7WzQ5GzdZsm7Pr7NY5zRAm4CsNoC9ImmCSBO8fL9vupO6XddajtH5xm5WaT3a062TmYVmnXrG6KVpW4RSSFNIyLW/+5MHPn2/HedWj9Ltb3OR353c3GL1Y47a55XXL6qopy4reAjNUNWVRU1rTvMyCl9en88VPy7jublpMr3jHm7Iu6rZqu7oTLRNtL5kae6l7LkUnOqEE67uqKf8HQufxGKbHvPgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce6e1ac296f3cc56ed87bed49ef06a47/e4706/OpenHabian_07.avif 230w", "/en/static/ce6e1ac296f3cc56ed87bed49ef06a47/d1af7/OpenHabian_07.avif 460w", "/en/static/ce6e1ac296f3cc56ed87bed49ef06a47/b6582/OpenHabian_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ce6e1ac296f3cc56ed87bed49ef06a47/a0b58/OpenHabian_07.webp 230w", "/en/static/ce6e1ac296f3cc56ed87bed49ef06a47/bc10c/OpenHabian_07.webp 460w", "/en/static/ce6e1ac296f3cc56ed87bed49ef06a47/87ca7/OpenHabian_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce6e1ac296f3cc56ed87bed49ef06a47/81c8e/OpenHabian_07.png 230w", "/en/static/ce6e1ac296f3cc56ed87bed49ef06a47/08a84/OpenHabian_07.png 460w", "/en/static/ce6e1ac296f3cc56ed87bed49ef06a47/e9beb/OpenHabian_07.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ce6e1ac296f3cc56ed87bed49ef06a47/e9beb/OpenHabian_07.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Go to the FireMotD directory `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`cd /opt/FireMotD`}</code></li>
      <li parentName="ol">{`And run the following command `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`sudo ./FireMotD -I -v`}</code>{` (you will be prompted to use the root password that is `}<strong parentName="li">{`openhabian`}</strong>{` by default)`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1ef1b449fe4549ba237dee81732005ce/e9beb/OpenHabian_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100.8695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADfklEQVQ4yy2Q227jNhiE9Sib2JbEM8WzRIqkZMmyLB+SNJum3ZsCBfb9H6HIboG5mP/im/kxxXZdxmPM09imaPtgg9ets95pZ1zoTGtcZ30KrbfWt7azzlvakBqWkOLiz8/319fbsq35NJ+2dZjGcRpTHk/ned3WZZ361OUxpaGPKfjYtd5qpwhDmKLi8bJNcw5D0sGrrjW/4rvQGaeNU13faiMgrinDlGPWYADLqj5AVEFUFz/++fF4u54f1+N1W+7X03XN8+hC6/pOORVy6IdeOaWc5IozSUtwONT7XbXbVbvi55B+BPdhzbu170Z/Sas3Kd5k8yb4S8MfjGwYXWA97Z+n/fOp3J+qw6UuY7krXrQynTFGSa1iiuNx6GNgjGAMuOC84RgBhEDTUMYwIZBgQBiWjFxAVdytblPnY/A5HpfZR287QxhmgjZaCC0gARUsAaoABgDXkADEEOd4JbC4W5mmnMbkU1xvm4+hz32IvpFMWUkbUtb7stpVsEQMQ/JVixnmDC+wLl6cSXOOQwxjXm9bno55Gn3stNM+eh89phCgSjttO8sFq2BZwhKBw6k6FHcjffZpiD73cczHZTpfL6d18bEL0ecxTfPo+05Z5VrTdjbmvsYVRuUJ1MVNCxtsHlPX+zyN03neHrfTusQUGskayfvUW6e5oEIyqXgaetwQgusv+M2ZcTnmIfreny/Luq197pVuhGCUQsoQwjWhkBBACKiq3eHwBAlkGKywLv5t3Yczb0q+SPFu9B9GP0Rz5/SC4YbhndM7I3dGrhTfKDmDaqnLFaMHwSuGRT94qTlpiG51G5wyAlEEKSQcc8mY5KzBXHJpBOEYMwQIAARChgBDRc5eaW6cdt5pq6QRykjnrXVaWSGNNFYYp2yrlRHaSspxBQ41KmtYFukXLHQjVGNaE6I3Tn2pM7+N6zTlGBGACYCoKuvdvnzaH77t9t+KmDqpGKawgiXlWCiOCEAUUE7+Zygo6/2+fN6Xz8/7b7vD0+7wdCify3pf/PX398fjGmOKQx9z6JOP2R/nYTym/uvsTKtSDsMxDWOMyYe+bb39/U7x+fn+/eN1u63z+bReL/NyGqZxWqbtdhnm43Q6jnO+3rdlnW+P7fF6d51JQzRWAlwX8zqHoXfBtr3oovJZ29DY0LS9WZJoFJdaKCuFbszXZrL5tTwTDFH0H8kQAvyJGS/mAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1ef1b449fe4549ba237dee81732005ce/e4706/OpenHabian_08.avif 230w", "/en/static/1ef1b449fe4549ba237dee81732005ce/d1af7/OpenHabian_08.avif 460w", "/en/static/1ef1b449fe4549ba237dee81732005ce/b6582/OpenHabian_08.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1ef1b449fe4549ba237dee81732005ce/a0b58/OpenHabian_08.webp 230w", "/en/static/1ef1b449fe4549ba237dee81732005ce/bc10c/OpenHabian_08.webp 460w", "/en/static/1ef1b449fe4549ba237dee81732005ce/87ca7/OpenHabian_08.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1ef1b449fe4549ba237dee81732005ce/81c8e/OpenHabian_08.png 230w", "/en/static/1ef1b449fe4549ba237dee81732005ce/08a84/OpenHabian_08.png 460w", "/en/static/1ef1b449fe4549ba237dee81732005ce/e9beb/OpenHabian_08.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1ef1b449fe4549ba237dee81732005ce/e9beb/OpenHabian_08.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`When you restart your Pi you will now be greeted by the useful FireMotD output.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      